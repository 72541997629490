export function shortenText(text: string, maxLength: number): string {
    if(!text) return text;
    if (text.length <= maxLength) {
        return text;
    }

    let truncatedText = text.substring(0, maxLength);
    let lastSpace = truncatedText.lastIndexOf(' ');

    if (lastSpace === -1) {
        return truncatedText + '…';
    }

    truncatedText = truncatedText.substring(0, lastSpace);
    return truncatedText + '…';
}
