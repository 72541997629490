"use client";
import Image from "next/image";
import logo from "@/public/logo.png";
import Link from "next/link";
import React, {useEffect, useState} from "react";
import {Button} from "@/components/tailwind/ui/button";
import {createClientComponentClient} from "@supabase/auth-helpers-nextjs";
import type {Database} from "@/app/database.types";
import {
    BookUserIcon,
    ChevronLeftIcon,
    GalleryHorizontalIcon,
    GitCommit,
    HelpCircle,
    Home,
    LogInIcon,
    Menu,
    MessageCircle,
    SquareGanttChart,
    SquarePenIcon,
    UserIcon,
    VenetianMaskIcon
} from "lucide-react";
import {usePathname, useRouter} from "next/navigation";
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger
} from "@/components/tailwind/ui/sheet";
import {shortenText} from "@/utils/text";
import {navigateBackwards} from "@/components/tailwind/app/navigation";
import {HAS_MOBILE_WIDTH, MOBILE_STATE_UNKNOWN} from "@/components/tailwind/app/device";
import {v4 as uuidv4} from "uuid";

const titleMap = {
    "/p/timeline/global": "All Pages",
    "/p/timeline/mine": "Your Pages",
    "/other/account": "Account",
    "/other/feedback": "Feedback",
    "/releases": "Releases",
    "/roadmap": "Roadmap",
    "/help": "Help",

}

export default function NavBar(props: {}) {
    const [authSession, setAuthSession] = useState(null);
    const [profile, setProfile] = useState(null);
    const [noHeader, setNoHeader] = useState(false);
    const supabase = createClientComponentClient<Database>()
    const router = useRouter();
    const pathName = usePathname();


    useEffect(() => {
        if (window.self !== window.top) {
            setNoHeader(true);
        }
        if (window.location.search.includes("noheader=true")) {
            setNoHeader(true);
        }

        supabase.auth.getSession().then(({data}) => {
            setAuthSession(data.session);
        });

        supabase.auth.onAuthStateChange((_event, session) => {
            setAuthSession(session);
        })
    }, [])

    useEffect(() => {
        if (authSession?.user?.id) {
            supabase
                .from("profiles")
                .select("full_name, username, website, avatar_url, id")
                .eq("id", authSession?.user?.id)
                .single().then(({data}) =>
                setProfile(data));
        }
    }, [authSession]);


    const isLoggedIn = !!authSession?.user?.id;
    const isAnon = authSession?.user && (authSession.user as any).is_anonymous;

    if (MOBILE_STATE_UNKNOWN) {
        return null;
    }

    if (noHeader) {
        return null;
    }

    return (
        <div
            className="etp-navbar flex justify-between items-center bg-gray-200 py-4 max-h-12 space-x-2 px-4 bg-background fg-foreground dark:bg-foreground  dark:text-background md:shadow-sm">
            <div className="flex items-center gap-2">
                <Button variant="ghost"
                        size={HAS_MOBILE_WIDTH ? "icon" : "sm"}
                        className="bg-gray-200 dark:bg-foreground  dark:text-background" onClick={() => {
                    navigateBackwards(router)
                }}>{!HAS_MOBILE_WIDTH ? "Back" : <ChevronLeftIcon/>}</Button>
                <Button onClick={() => router.push(`/p/${uuidv4()}?i=`)} variant="ghost"
                        size={HAS_MOBILE_WIDTH ? "icon" : "sm"}
                        className="flex flex-shrink  bg-gray-200 dark:bg-foreground dark:text-background">
                    {!HAS_MOBILE_WIDTH ? "New" : <SquarePenIcon size={20}/>}
                </Button>

            </div>

            <div className="flex items-center gap-2">

                <div><Link href="/"><Image src={logo} alt="Edit This Page"
                                           className="w-36 hidden md:inline-block "/>
                </Link>{HAS_MOBILE_WIDTH ? null : titleMap[pathName] || null}</div>

            {pathName !== "/p/timeline/global" ?
                <Button variant="ghost" className=" bg-gray-200 dark:bg-foreground  dark:text-background"
                        size={HAS_MOBILE_WIDTH ? "icon" : "sm"}
                        onClick={() => router.push("/p/timeline/global")}>{!HAS_MOBILE_WIDTH ? "All Pages" :
                    <GalleryHorizontalIcon/>}</Button> : null}
            {isLoggedIn && pathName !== "/p/timeline/mine" ?
                <Button variant="ghost" size={HAS_MOBILE_WIDTH ? "icon" : "sm"}
                        className="flex  flex-shrink bg-gray-200 dark:bg-foreground dark:text-background"
                        onClick={() => router.push("/p/timeline/mine")}>{!HAS_MOBILE_WIDTH ? "My Pages" :
                    <BookUserIcon/>}</Button> : null}


        </div>
    <div className="flex items-end">
        <Button variant="ghost" size={HAS_MOBILE_WIDTH ? "icon" : "sm"}
                className="flex  flex-shrink bg-gray-200 dark:bg-foreground dark:text-background"
                onClick={() => router.push(`/other/account`)}>{!isLoggedIn ?
            <LogInIcon className="mr-2"></LogInIcon> : (isAnon ?
                <VenetianMaskIcon className="mr-2"></VenetianMaskIcon> :
                null)}<span
            className="hidden md:inline">{!isLoggedIn || isAnon ? 'Sign In' : shortenText(profile?.full_name, 12)}</span><span
            className="inline md:hidden"><UserIcon/></span> </Button>
        <Sheet>
            <SheetTrigger asChild>
                <Button variant="ghost" size={HAS_MOBILE_WIDTH ? "icon" : "sm"}
                        className="flex-shrink-0 bg-gray-200 dark:bg-foreground dark:text-background">
                            <Menu></Menu></Button>
                    </SheetTrigger>
                    <SheetContent>
                        <SheetHeader>
                            <SheetTitle>Edit This Page</SheetTitle>
                            <SheetDescription>
                                Edit This Page is a creative social note sharing service, you can create
                                notes, stylize them and then share them. Once shared other people can edit
                                them reusing your styles and making their own notes with them. <br/><br/>Create,
                                Edit, Share, Repeat.
                            </SheetDescription>
                        </SheetHeader>

                        <div className="px-6 py-8 mt-2">
                            <nav>
                                <ul className="space-y-4">

                                    <li>
                                        <a
                                            href="/"
                                            className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
                                        >
                                            <Home size={20}/>
                                            Home
                                        </a>
                                    </li>
                                    <li>
                                        <Link
                                            href="/help"
                                            className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
                                        >
                                            <HelpCircle size={20}/>
                                            Help
                                        </Link>
                                    </li>
                                    <li>
                                        <a
                                            href="/other/feedback"
                                            className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
                                        >
                                            <MessageCircle size={20}/>
                                            Feedback/Contact
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/releases"
                                            className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
                                        >
                                            <GitCommit size={20}/>
                                            Releases
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/roadmap"
                                            className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
                                        >
                                            <SquareGanttChart size={20}/>
                                            Roadmap
                                        </a>
                                    </li>

                                </ul>
                            </nav>
                        </div>
                        <SheetFooter>
                            {/*<SheetClose asChild>*/}
                            {/*    <Button type="submit">Close</Button>*/}
                            {/*</SheetClose>*/}
                        </SheetFooter>
                    </SheetContent>
                </Sheet>
            </div>
        </div>
    )
}
